<template>
  <Item :item="item" :parent="parent" class="w-full lg:max-w-[60vw]">
    <component
      :is="isAdminMode ? 'div' : 'form'"
      @submit.prevent="onFormSubmit"
    >
      <div v-if="success" class="content">
        <p>{{ item.settings.successMsg }}</p>
      </div>

      <fieldset v-else :disabled="isSubmitting">
        <is-guest>
          <input id="emailform-name" class="text-gray-700" type="text" />
        </is-guest>

        <div class="flex flex-wrap gap-4">
          <TextField
            ref="textfield"
            label="Email"
            :item="item.items[0]"
            :parent="item"
            class="flex-grow text-gray-700 emailform-input"
          />

          <SubmitBtn
            :item="item.items[1]"
            :parent="item"
            class="emailform-btn"
          />
        </div>
      </fieldset>
    </component>
  </Item>
</template>

<script>
import { get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'
import computedPublicUrl from '~/mixins/computedPublicUrl'

export default {
  name: 'EmailFormItem',

  components: {
    Item: () => import('@/components/Item'),
    TextField: () => import('@/components/Items/TextField'),
    SubmitBtn: () => import('@/components/Items/SubmitBtn'),
  },

  mixins: [itemMixin, computedPublicUrl],

  data() {
    return {
      success: false,
    }
  },

  computed: {
    isAdminMode: get('isAdminMode'),
    pageTitle: get('page/@obj.title'),
    pageId: get('page/@obj.id'),
    user: get('user/data'),

    isSubmitting() {
      return this.$wait.is('submitting form')
    },
  },

  methods: {
    async onFormSubmit() {
      if (this.isAdminMode) {
        return false
      }

      const $honeypotField = document.querySelector('#emailform-name')
      const $input = this.$refs.textfield.$el.querySelector('input')
      const inputValue = $input.value

      if (!inputValue || ($honeypotField && $honeypotField.value)) {
        return false
      }

      if (this.isSubmitting) {
        return false
      }

      this.$wait.start('submitting form')

      const postData = {
        publicUrl: this.publicUrl,
        submittedEmail: inputValue,
        formName: this.item.settings.formName,
      }

      try {
        await this.$api(`/form-submission`, postData, false)
        $input.value = ''
        this.success = true
      } catch (error) {
        return alert('Form submission failed, please try again soon.')
      } finally {
        this.$wait.end('submitting form')
      }
    },
  },
}
</script>

<style lang="postcss">
fieldset:disabled {
  opacity: 0.5;
}

#emailform-name {
  position: absolute;
  left: -999em;
}

.emailform-input {
  flex: 3 1 23ch;
}

.emailform-btn {
  flex: 1 1 10ch;
}
</style>
