import { get } from 'vuex-pathify'

export default {
  computed: {
    customDomainStatus: get('page/customDomainStatus'),
    customDomainName: get('page/customDomainName'),
    pageSlug: get('page/obj@slug'),

    protocol() {
      return this.$config.appDomain === 'siddler.com' ? 'https' : 'http'
    },

    publicUrl() {
      if (this.customDomainStatus === 'active') {
        return `${this.protocol}://${this.customDomainName}`
      }

      return `${this.protocol}://${this.pageSlug}.${this.$config.appDomain}/`
    },
  },
}
